/* Color custom properties */

:root,
.color-background-1 {
  --color-foreground: var(--color-base-text);
  --color-background: var(--color-base-background-1);
  --gradient-background: var(--gradient-base-background-1);
}

.color-background-2 {
  --color-foreground: var(--color-base-text);
  --color-background: var(--color-base-background-2);
  --gradient-background: var(--gradient-base-background-2);
}

.color-inverse {
  --color-foreground: var(--color-base-background-1);
  --color-background: var(--color-base-text);
  --gradient-background: rgb(var(--color-base-text));
}

.color-accent-1 {
  --color-foreground: var(--color-base-solid-button-labels);
  --color-background: var(--color-base-accent-1);
  --gradient-background: var(--gradient-base-accent-1);
}

.color-accent-2 {
  --color-foreground: var(--color-base-solid-button-labels);
  --color-background: var(--color-base-accent-2);
  --gradient-background: var(--gradient-base-accent-2);
}

.color-foreground-outline-button {
  --color-foreground: var(--color-base-outline-button-labels);
}

.color-foreground-accent-1 {
  --color-foreground: var(--color-base-accent-1);
}

.color-foreground-accent-2 {
  --color-foreground: var(--color-base-accent-2);
}

:root,
.color-background-1 {
  --color-link: var(--color-base-outline-button-labels);
  --alpha-link: 0.85;
}

.color-background-2,
.color-inverse,
.color-accent-1,
.color-accent-2 {
  --color-link: var(--color-foreground);
  --alpha-link: 0.7;
}

:root,
.color-background-1 {
  --color-button: var(--color-base-accent-1);
  --color-button-text: var(--color-base-solid-button-labels);
  --alpha-button-background: 1;
  --alpha-button-border: 1;
}

.color-background-2,
.color-inverse,
.color-accent-1,
.color-accent-2 {
  --color-button: var(--color-foreground);
  --color-button-text: var(--color-background);
}

.button--secondary {
  --color-button: var(--color-base-outline-button-labels);
  --color-button-text: var(--color-base-outline-button-labels);
  --color-button: var(--color-background);
  --alpha-button-background: 1;
}

.color-background-2 .button--secondary,
.color-accent-1 .button--secondary,
.color-accent-2 .button--secondary {
  --color-button: var(--color-background);
  --color-button-text: var(--color-foreground);
}

.color-inverse .button--secondary {
  --color-button: var(--color-background);
  --color-button-text: var(--color-foreground);
}

.button--tertiary {
  --color-button: var(--color-base-outline-button-labels);
  --color-button-text: var(--color-base-outline-button-labels);
  --alpha-button-background: 0;
  --alpha-button-border: 0.2;
}

.color-background-2 .button--tertiary,
.color-inverse .button--tertiary,
.color-accent-1 .button--tertiary,
.color-accent-2 .button--tertiary {
  --color-button: var(--color-foreground);
  --color-button-text: var(--color-foreground);
}

:root,
.color-background-1 {
  --color-badge-background: var(--color-background);
  --color-badge-border: var(--color-foreground);
  --alpha-badge-border: 0.1;
}

.color-background-2,
.color-inverse,
.color-accent-1,
.color-accent-2 {
  --color-badge-background: var(--color-background);
  --color-badge-border: var(--color-background);
  --alpha-badge-border: 1;
}

:root,
.color-background-1,
.color-background-2 {
  --color-card-hover: var(--color-base-text);
}

.color-inverse {
  --color-card-hover: var(--color-base-background-1);
}

.color-accent-1,
.color-accent-2 {
  --color-card-hover: var(--color-base-solid-button-labels);
}

:root,
.color-icon-text {
  --color-icon: rgb(var(--color-base-text));
}

.color-icon-accent-1 {
  --color-icon: rgb(var(--color-base-accent-1));
}

.color-icon-accent-2 {
  --color-icon: rgb(var(--color-base-accent-2));
}

.color-icon-outline-button {
  --color-icon: rgb(var(--color-base-outline-button-labels));
}

.product-card-wrapper .card,
.contains-card--product {
  --border-radius: var(--product-card-corner-radius);
  --border-width: var(--product-card-border-width);
  --border-opacity: var(--product-card-border-opacity);
  --shadow-horizontal-offset: var(--product-card-shadow-horizontal-offset);
  --shadow-vertical-offset: var(--product-card-shadow-vertical-offset);
  --shadow-blur-radius: var(--product-card-shadow-blur-radius);
  --shadow-opacity: var(--product-card-shadow-opacity);
  --shadow-visible: var(--product-card-shadow-visible);
  --image-padding: var(--product-card-image-padding);
  --text-alignment: var(--product-card-text-alignment);
}

.collection-card-wrapper .card,
.contains-card--collection {
  --border-radius: var(--collection-card-corner-radius);
  --border-width: var(--collection-card-border-width);
  --border-opacity: var(--collection-card-border-opacity);
  --shadow-horizontal-offset: var(--collection-card-shadow-horizontal-offset);
  --shadow-vertical-offset: var(--collection-card-shadow-vertical-offset);
  --shadow-blur-radius: var(--collection-card-shadow-blur-radius);
  --shadow-opacity: var(--collection-card-shadow-opacity);
  --shadow-visible: var(--collection-card-shadow-visible);
  --image-padding: var(--collection-card-image-padding);
  --text-alignment: var(--collection-card-text-alignment);
}

.article-card-wrapper .card,
.contains-card--article {
  --border-radius: var(--blog-card-corner-radius);
  --border-width: var(--blog-card-border-width);
  --border-opacity: var(--blog-card-border-opacity);
  --shadow-horizontal-offset: var(--blog-card-shadow-horizontal-offset);
  --shadow-vertical-offset: var(--blog-card-shadow-vertical-offset);
  --shadow-blur-radius: var(--blog-card-shadow-blur-radius);
  --shadow-opacity: var(--blog-card-shadow-opacity);
  --shadow-visible: var(--blog-card-shadow-visible);
  --image-padding: var(--blog-card-image-padding);
  --text-alignment: var(--blog-card-text-alignment);
}

.contains-content-container,
.content-container {
  --border-radius: var(--text-boxes-radius);
  --border-width: var(--text-boxes-border-width);
  --border-opacity: var(--text-boxes-border-opacity);
  --shadow-horizontal-offset: var(--text-boxes-shadow-horizontal-offset);
  --shadow-vertical-offset: var(--text-boxes-shadow-vertical-offset);
  --shadow-blur-radius: var(--text-boxes-shadow-blur-radius);
  --shadow-opacity: var(--text-boxes-shadow-opacity);
  --shadow-visible: var(--text-boxes-shadow-visible);
}

.contains-media,
.global-media-settings {
  --border-radius: var(--media-radius);
  --border-width: var(--media-border-width);
  --border-opacity: var(--media-border-opacity);
  --shadow-horizontal-offset: var(--media-shadow-horizontal-offset);
  --shadow-vertical-offset: var(--media-shadow-vertical-offset);
  --shadow-blur-radius: var(--media-shadow-blur-radius);
  --shadow-opacity: var(--media-shadow-opacity);
  --shadow-visible: var(--media-shadow-visible);
}

/* base */

.no-js:not(html) {
  display: none !important;
}

html.no-js .no-js:not(html) {
  display: block !important;
}

.no-js-inline {
  display: none !important;
}

html.no-js .no-js-inline {
  display: inline-block !important;
}

html.no-js .no-js-hidden {
  display: none !important;
}

.page-width {
  max-width: var(--page-width);
  margin: 0 auto;
  padding: 0 1.5rem;
}

.page-width-desktop {
  padding: 0;
  margin: 0 auto;
}

@media screen and (min-width: 750px) {
  .page-width {
    padding: 0 5rem;
  }

  .page-width--narrow {
    padding: 0 9rem;
  }

  .page-width-desktop {
    padding: 0;
  }

  .page-width-tablet {
    padding: 0 5rem;
  }
}

@media screen and (min-width: 990px) {
  .page-width--narrow {
    max-width: 72.6rem;
    padding: 0;
  }

  .page-width-desktop {
    max-width: var(--page-width);
    padding: 0 5rem;
  }
}

.isolate {
  position: relative;
  z-index: 0;
}

.section + .section {
  margin-top: var(--spacing-sections-mobile);
}

@media screen and (min-width: 750px) {
  .section + .section {
    margin-top: var(--spacing-sections-desktop);
  }
}

.element-margin-top {
  margin-top: 5rem;
}

@media screen and (min-width: 750px) {
  .element-margin {
    margin-top: calc(5rem + var(--page-width-margin));
  }
}

body,
.color-background-1,
.color-background-2,
.color-inverse,
.color-accent-1,
.color-accent-2 {
  color: rgba(var(--color-foreground), 0.75);
  background-color: rgb(var(--color-background));
}

.background-secondary {
  background-color: rgba(var(--color-foreground), 0.04);
}

.grid-auto-flow {
  display: grid;
  grid-auto-flow: column;
}

.page-margin,
.shopify-challenge__container {
  margin: 7rem auto;
}

.rte-width {
  max-width: 82rem;
  margin: 0 auto 2rem;
}

.list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.hidden {
  display: none !important;
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  word-wrap: normal !important;
}

.visually-hidden--inline {
  margin: 0;
  height: 1em;
}

.overflow-hidden {
  overflow: hidden;
}

.skip-to-content-link:focus {
  z-index: 9999;
  position: inherit;
  overflow: auto;
  width: auto;
  height: auto;
  clip: auto;
}

.full-width-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

::selection {
  background-color: rgba(var(--color-foreground), 0.2);
}

.text-body {
  font-size: 1.5rem;
  letter-spacing: 0.06rem;
  line-height: calc(1 + 0.8 / var(--font-body-scale));
  font-family: var(--font-body-family);
  font-style: var(--font-body-style);
  font-weight: var(--font-body-weight);
}

h1,
h2,
h3,
h4,
h5,
.h0,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: var(--font-heading-family);
  font-style: var(--font-heading-style);
  font-weight: var(--font-heading-weight);
  letter-spacing: calc(var(--font-heading-scale) * 0.06rem);
  color: rgb(var(--color-foreground));
  line-height: calc(1 + 0.3 / max(1, var(--font-heading-scale)));
  word-break: break-word;
}

.hxl {
  font-size: calc(var(--font-heading-scale) * 5rem);
}

@media only screen and (min-width: 750px) {
  .hxl {
    font-size: calc(var(--font-heading-scale) * 6.2rem);
  }
}

.h0 {
  font-size: calc(var(--font-heading-scale) * 4rem);
}

@media only screen and (min-width: 750px) {
  .h0 {
    font-size: calc(var(--font-heading-scale) * 5.2rem);
  }
}

h1,
.h1 {
  font-size: calc(var(--font-heading-scale) * 3rem);
}

@media only screen and (min-width: 750px) {
  h1,
  .h1 {
    font-size: calc(var(--font-heading-scale) * 4rem);
  }
}

h2,
.h2 {
  font-size: calc(var(--font-heading-scale) * 2rem);
}

@media only screen and (min-width: 750px) {
  h2,
  .h2 {
    font-size: calc(var(--font-heading-scale) * 2.4rem);
  }
}

h3,
.h3 {
  font-size: calc(var(--font-heading-scale) * 1.7rem);
}

@media only screen and (min-width: 750px) {
  h3,
  .h3 {
    font-size: calc(var(--font-heading-scale) * 1.8rem);
  }
}

h4,
.h4 {
  font-family: var(--font-heading-family);
  font-style: var(--font-heading-style);
  font-size: calc(var(--font-heading-scale) * 1.5rem);
}

h5,
.h5 {
  font-size: calc(var(--font-heading-scale) * 1.2rem);
}

@media only screen and (min-width: 750px) {
  h5,
  .h5 {
    font-size: calc(var(--font-heading-scale) * 1.3rem);
  }
}

h6,
.h6 {
  color: rgba(var(--color-foreground), 0.75);
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
}

blockquote {
  font-style: italic;
  color: rgba(var(--color-foreground), 0.75);
  border-left: 0.2rem solid rgba(var(--color-foreground), 0.2);
  padding-left: 1rem;
}

@media screen and (min-width: 750px) {
  blockquote {
    padding-left: 1.5rem;
  }
}

.caption {
  font-size: 1rem;
  letter-spacing: 0.07rem;
  line-height: calc(1 + 0.7 / var(--font-body-scale));
}

@media screen and (min-width: 750px) {
  .caption {
    font-size: 1.2rem;
  }
}

.caption-with-letter-spacing {
  font-size: 1rem;
  letter-spacing: 0.13rem;
  line-height: calc(1 + 0.2 / var(--font-body-scale));
  text-transform: uppercase;
}

.caption-with-letter-spacing--medium {
  font-size: 1.2rem;
  letter-spacing: .16rem;
}

.caption-with-letter-spacing--large {
  font-size: 1.4rem;
  letter-spacing: 0.18rem;
}

.caption-large,
.customer .field input,
.customer select,
.field__input,
.form__label,
.select__select {
  font-size: 1.3rem;
  line-height: calc(1 + 0.5 / var(--font-body-scale));
  letter-spacing: 0.04rem;
}

.color-foreground {
  color: rgb(var(--color-foreground));
}

table:not([class]) {
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 1.4rem;
  border-style: hidden;
  box-shadow: 0 0 0 0.1rem rgba(var(--color-foreground), 0.2);
  /* draws the table border  */
}

table:not([class]) td,
table:not([class]) th {
  padding: 1em;
  border: 0.1rem solid rgba(var(--color-foreground), 0.2);
}

@media screen and (max-width: 749px) {
  .small-hide {
    display: none !important;
  }
}

@media screen and (min-width: 750px) and (max-width: 989px) {
  .medium-hide {
    display: none !important;
  }
}

@media screen and (min-width: 990px) {
  .large-up-hide {
    display: none !important;
  }
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

.light {
  opacity: 0.7;
}

a:empty,
ul:empty,
dl:empty,
div:empty,
section:empty,
article:empty,
p:empty,
h1:empty,
h2:empty,
h3:empty,
h4:empty,
h5:empty,
h6:empty {
  display: none;
}

.link,
.customer a {
  cursor: pointer;
  display: inline-block;
  border: none;
  box-shadow: none;
  text-decoration: underline;
  text-underline-offset: 0.3rem;
  color: rgb(var(--color-link));
  background-color: transparent;
  font-size: 1.4rem;
  font-family: inherit;
}

.link--text {
  color: rgb(var(--color-foreground));
}

.link--text:hover {
  color: rgba(var(--color-foreground), 0.75);
}

.link-with-icon {
  display: inline-flex;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  text-decoration: none;
  margin-bottom: 4.5rem;
  white-space: nowrap;
}

.link-with-icon .icon {
  width: 1.5rem;
  margin-left: 1rem;
}

a:not([href]) {
  cursor: not-allowed;
}

.circle-divider::after {
  content: '\2022';
  margin: 0 1.3rem 0 1.5rem;
}

.circle-divider:last-of-type::after {
  display: none;
}

hr {
  border: none;
  height: 0.1rem;
  background-color: rgba(var(--color-foreground), 0.2);
  display: block;
  margin: 5rem 0;
}

@media screen and (min-width: 750px) {
  hr {
    margin: 7rem 0;
  }
}

.full-unstyled-link {
  text-decoration: none;
  color: currentColor;
  display: block;
}

.placeholder {
  background-color: rgba(var(--color-foreground), 0.04);
  color: rgba(var(--color-foreground), 0.55);
  fill: rgba(var(--color-foreground), 0.55);
}

details > * {
  box-sizing: border-box;
}

.break {
  word-break: break-word;
}

.visibility-hidden {
  visibility: hidden;
}

@media (prefers-reduced-motion) {
  .motion-reduce {
    transition: none !important;
    animation: none !important;
  }
}

:root {
  --duration-short: 100ms;
  --duration-default: 200ms;
  --duration-long: 500ms;
}

.underlined-link,
.customer a {
  color: rgba(var(--color-link), var(--alpha-link));
  text-underline-offset: 0.3rem;
  text-decoration-thickness: 0.1rem;
  transition: text-decoration-thickness ease 100ms;
}

.underlined-link:hover,
.customer a:hover {
  color: rgb(var(--color-link));
  text-decoration-thickness: 0.2rem;
}

.icon-arrow {
  width: 1.5rem;
}

h3 .icon-arrow,
.h3 .icon-arrow {
  width: calc(var(--font-heading-scale) * 1.5rem);
}

/* arrow animation */
.animate-arrow .icon-arrow path {
  transform: translateX(-0.25rem);
  transition: transform var(--duration-short) ease;
}

.animate-arrow:hover .icon-arrow path {
  transform: translateX(-0.05rem);
}

/* base-details-summary */
summary {
  cursor: pointer;
  list-style: none;
  position: relative;
}

summary .icon-caret {
  position: absolute;
  height: 0.6rem;
  right: 1.5rem;
  top: calc(50% - 0.2rem);
}

summary::-webkit-details-marker {
  display: none;
}

.disclosure-has-popup {
  position: relative;
}

.disclosure-has-popup[open] > summary::before {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: block;
  cursor: default;
  content: ' ';
  background: transparent;
}

.disclosure-has-popup > summary::before {
  display: none;
}

.disclosure-has-popup[open] > summary + * {
  z-index: 100;
}

@media screen and (min-width: 750px) {
  .disclosure-has-popup[open] > summary + * {
    z-index: 4;
  }

  .facets .disclosure-has-popup[open] > summary + * {
    z-index: 2;
  }
}

/* base-focus */
/*
  Focus ring - default (with offset)
*/

*:focus {
  outline: 0;
  box-shadow: none;
}

*:focus-visible {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: 0.3rem;
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3);
}

/* Fallback - for browsers that don't support :focus-visible, a fallback is set for :focus */
.focused,
.no-js *:focus {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: 0.3rem;
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3);
}

/* Negate the fallback side-effect for browsers that support :focus-visible */
.no-js *:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

/*
  Focus ring - inset
*/

.focus-inset:focus-visible {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: -0.2rem;
  box-shadow: 0 0 0.2rem 0 rgba(var(--color-foreground), 0.3);
}

.focused.focus-inset,
.no-js .focus-inset:focus {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: -0.2rem;
  box-shadow: 0 0 0.2rem 0 rgba(var(--color-foreground), 0.3);
}

.no-js .focus-inset:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

/*
  Focus ring - none
*/

/* Dangerous for a11y - Use with care */
.focus-none {
  box-shadow: none !important;
  outline: 0 !important;
}

.focus-offset:focus-visible {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: 1rem;
  box-shadow: 0 0 0 1rem rgb(var(--color-background)),
    0 0 0.2rem 1.2rem rgba(var(--color-foreground), 0.3);
}

.focus-offset.focused,
.no-js .focus-offset:focus {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: 1rem;
  box-shadow: 0 0 0 1rem rgb(var(--color-background)),
    0 0 0.2rem 1.2rem rgba(var(--color-foreground), 0.3);
}

.no-js .focus-offset:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

/* component-title */
.title,
.title-wrapper-with-link {
  margin: 3rem 0 2rem;
}

.title-wrapper-with-link .title {
  margin: 0;
}

.title .link {
  font-size: inherit;
}

.title-wrapper {
  margin-bottom: 3rem;
}

.title-wrapper-with-link {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

.title--primary {
  margin: 4rem 0;
}

.title-wrapper--self-padded-tablet-down,
.title-wrapper--self-padded-mobile {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media screen and (min-width: 750px) {
  .title-wrapper--self-padded-mobile {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 990px) {
  .title,
  .title-wrapper-with-link {
    margin: 5rem 0 3rem;
  }

  .title--primary {
    margin: 2rem 0;
  }

  .title-wrapper-with-link {
    align-items: center;
  }

  .title-wrapper-with-link .title {
    margin-bottom: 0;
  }

  .title-wrapper--self-padded-tablet-down {
    padding-left: 0;
    padding-right: 0;
  }
}

.title-wrapper-with-link .link-with-icon {
  margin: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.title-wrapper-with-link .link-with-icon svg {
  width: 1.5rem;
}

.title-wrapper-with-link a {
  color: rgb(var(--color-link));
  margin-top: 0;
  flex-shrink: 0;
}

.title-wrapper--no-top-margin {
  margin-top: 0;
}

.title-wrapper--no-top-margin > .title {
  margin-top: 0;
}

.subtitle {
  font-size: 1.8rem;
  line-height: calc(1 + 0.8 / var(--font-body-scale));
  letter-spacing: 0.06rem;
  color: rgba(var(--color-foreground), 0.7);
}

.subtitle--small {
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
}

.subtitle--medium {
  font-size: 1.6rem;
  letter-spacing: 0.08rem;
}

/* component-grid */
.grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  padding: 0;
  list-style: none;
  column-gap: var(--grid-mobile-horizontal-spacing);
  row-gap: var(--grid-mobile-vertical-spacing);
}

@media screen and (min-width: 750px) {
  .grid {
    column-gap: var(--grid-desktop-horizontal-spacing);
    row-gap: var(--grid-desktop-vertical-spacing);
  }
}

.grid:last-child {
  margin-bottom: 0;
}

.grid__item {
  width: calc(25% - var(--grid-mobile-horizontal-spacing) * 3 / 4);
  max-width: calc(50% - var(--grid-mobile-horizontal-spacing) / 2);
  flex-grow: 1;
  flex-shrink: 0;
}

@media screen and (min-width: 750px) {
  .grid__item {
    width: calc(25% - var(--grid-desktop-horizontal-spacing) * 3 / 4);
    max-width: calc(50% - var(--grid-desktop-horizontal-spacing) / 2);
  }
}

.grid--gapless.grid {
  column-gap: 0;
  row-gap: 0;
}

@media screen and (max-width: 749px) {
  .grid__item.slider__slide--full-width {
    width: 100%;
    max-width: none;
  }
}

.grid--1-col .grid__item {
  max-width: 100%;
  width: 100%;
}

.grid--3-col .grid__item {
  width: calc(33.33% - var(--grid-mobile-horizontal-spacing) * 2 / 3);
}

@media screen and (min-width: 750px) {
  .grid--3-col .grid__item {
    width: calc(33.33% - var(--grid-desktop-horizontal-spacing) * 2 / 3);
  }
}

.grid--2-col .grid__item {
  width: calc(50% - var(--grid-mobile-horizontal-spacing) / 2);
}

@media screen and (min-width: 750px) {
  .grid--2-col .grid__item {
    width: calc(50% - var(--grid-desktop-horizontal-spacing) / 2);
  }

  .grid--4-col-tablet .grid__item {
    width: calc(25% - var(--grid-desktop-horizontal-spacing) * 3 / 4);
  }

  .grid--3-col-tablet .grid__item {
    width: calc(33.33% - var(--grid-desktop-horizontal-spacing) * 2 / 3);
  }

  .grid--2-col-tablet .grid__item {
    width: calc(50% - var(--grid-desktop-horizontal-spacing) / 2);
  }
}

@media screen and (max-width: 989px) {
  .grid--1-col-tablet-down .grid__item {
    width: 100%;
    max-width: 100%;
  }

  .slider--tablet.grid--peek {
    margin: 0;
    width: 100%;
  }

  .slider--tablet.grid--peek .grid__item {
    box-sizing: content-box;
    margin: 0;
  }
}

@media screen and (min-width: 990px) {
  .grid--6-col-desktop .grid__item {
    width: calc(16.66% - var(--grid-desktop-horizontal-spacing) * 5 / 6);
    max-width: calc(16.66% - var(--grid-desktop-horizontal-spacing) * 5 / 6);
  }

  .grid--5-col-desktop .grid__item {
    width: calc(20% - var(--grid-desktop-horizontal-spacing) * 4 / 5);
    max-width: calc(20% - var(--grid-desktop-horizontal-spacing) * 4 / 5);
  }

  .grid--4-col-desktop .grid__item {
    width: calc(25% - var(--grid-desktop-horizontal-spacing) * 3 / 4);
    max-width: calc(25% - var(--grid-desktop-horizontal-spacing) * 3 / 4);
  }

  .grid--3-col-desktop .grid__item {
    width: calc(33.33% - var(--grid-desktop-horizontal-spacing) * 2 / 3);
    max-width: calc(33.33% - var(--grid-desktop-horizontal-spacing) * 2 / 3);
  }

  .grid--2-col-desktop .grid__item {
    width: calc(50% - var(--grid-desktop-horizontal-spacing) / 2);
    max-width: calc(50% - var(--grid-desktop-horizontal-spacing) / 2);
  }
}

@media screen and (min-width: 990px) {
  .grid--1-col-desktop {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .grid--1-col-desktop .grid__item {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 749px) {
  .grid--peek.slider--mobile {
    margin: 0;
    width: 100%;
  }

  .grid--peek.slider--mobile .grid__item {
    box-sizing: content-box;
    margin: 0;
  }

  .grid--peek .grid__item {
    min-width: 35%;
  }

  .grid--peek.slider .grid__item:first-of-type {
    margin-left: 1.5rem;
  }

  /* Fix to show some space at the end of our sliders in all browsers */
  .grid--peek.slider:after {
    margin-left: calc(-1 * var(--grid-mobile-horizontal-spacing));
  }

  .grid--2-col-tablet-down .grid__item {
    width: calc(50% - var(--grid-mobile-horizontal-spacing) / 2);
  }

  .slider--tablet.grid--peek.grid--2-col-tablet-down .grid__item,
  .grid--peek .grid__item {
    width: calc(50% - var(--grid-mobile-horizontal-spacing) - 3rem);
  }

  .slider--tablet.grid--peek.grid--1-col-tablet-down .grid__item,
  .slider--mobile.grid--peek.grid--1-col-tablet-down .grid__item  {
    width: calc(100% - var(--grid-mobile-horizontal-spacing) - 3rem);
  }
}

@media screen and (min-width: 750px) and (max-width: 989px) {
  .slider--tablet.grid--peek .grid__item {
    width: calc(25% - var(--grid-desktop-horizontal-spacing) - 3rem);
  }

  .slider--tablet.grid--peek.grid--3-col-tablet .grid__item {
    width: calc(33.33% - var(--grid-desktop-horizontal-spacing) - 3rem);
  }

  .slider--tablet.grid--peek.grid--2-col-tablet .grid__item,
  .slider--tablet.grid--peek.grid--2-col-tablet-down .grid__item {
    width: calc(50% - var(--grid-desktop-horizontal-spacing) - 3rem);
  }

  .slider--tablet.grid--peek .grid__item:first-of-type {
    margin-left: 1.5rem;
  }

  .grid--2-col-tablet-down .grid__item {
    width: calc(50% - var(--grid-desktop-horizontal-spacing) / 2);
  }

  .grid--1-col-tablet-down.grid--peek .grid__item {
    width: calc(100% - var(--grid-desktop-horizontal-spacing) - 3rem);
  }
}

/* component-media */
.media {
  display: block;
  background-color: rgba(var(--color-foreground), 0.1);
  position: relative;
  overflow: hidden;
}

.media--transparent {
  background-color: transparent;
}

.media > *:not(.zoom):not(.deferred-media__poster-button),
.media model-viewer {
  display: block;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.media > img {
  object-fit: cover;
  object-position: center center;
  transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.media--square {
  padding-bottom: 100%;
}

.media--portrait {
  padding-bottom: 125%;
}

.media--landscape {
  padding-bottom: 66.6%;
}

.media--cropped {
  padding-bottom: 56%;
}

.media--16-9 {
  padding-bottom: 56.25%;
}

.media--circle {
  padding-bottom: 100%;
  border-radius: 50%;
}

.media.media--hover-effect > img + img {
  opacity: 0;
}

@media screen and (min-width: 990px) {
  .media--cropped {
    padding-bottom: 63%;
  }
}

deferred-media {
  display: block;
}

/* component-button */
/* Button - default */

.button,
.shopify-challenge__button,
.customer button,
button.shopify-payment-button__button--unbranded,
.shopify-payment-button [role="button"],
.cart__dynamic-checkout-buttons [role='button'],
.cart__dynamic-checkout-buttons iframe {
  --shadow-horizontal-offset: var(--buttons-shadow-horizontal-offset);
  --shadow-vertical-offset: var(--buttons-shadow-vertical-offset);
  --shadow-blur-radius: var(--buttons-shadow-blur-radius);
  --shadow-opacity: var(--buttons-shadow-opacity);
  --shadow-visible: var(--buttons-shadow-visible);
  --border-offset: var(--buttons-border-offset); /* reduce radius edge artifacts */
  --border-opacity: calc(1 - var(--buttons-border-opacity));
  border-radius: var(--buttons-radius-outset);
  position: relative;
}

.button,
.shopify-challenge__button,
.customer button,
button.shopify-payment-button__button--unbranded {
  min-width: calc(12rem + var(--buttons-border-width) * 2);
  min-height: calc(4.5rem + var(--buttons-border-width) * 2);
}

.shopify-payment-button__button--branded {
  z-index: auto;
}

.cart__dynamic-checkout-buttons iframe {
  box-shadow: var(--shadow-horizontal-offset) var(--shadow-vertical-offset) var(--shadow-blur-radius) rgba(var(--color-base-text), var(--shadow-opacity));
}

.button,
.shopify-challenge__button,
.customer button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 0 3rem;
  cursor: pointer;
  font: inherit;
  font-size: 1.5rem;
  text-decoration: none;
  color: rgb(var(--color-button-text));
  transition: box-shadow var(--duration-short) ease;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(var(--color-button), var(--alpha-button-background));
}

.button:before,
.shopify-challenge__button:before,
.customer button:before,
.shopify-payment-button__button--unbranded:before,
.shopify-payment-button [role="button"]:before,
.cart__dynamic-checkout-buttons [role='button']:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: var(--buttons-radius-outset);
  box-shadow: var(--shadow-horizontal-offset) var(--shadow-vertical-offset) var(--shadow-blur-radius) rgba(var(--color-shadow), var(--shadow-opacity));
}

.button:after,
.shopify-challenge__button:after,
.customer button:after,
.shopify-payment-button__button--unbranded:after {
  content: '';
  position: absolute;
  top: var(--buttons-border-width);
  right: var(--buttons-border-width);
  bottom: var(--buttons-border-width);
  left: var(--buttons-border-width);
  z-index: 1;
  border-radius: var(--buttons-radius);
  box-shadow: 0 0 0 calc(var(--buttons-border-width) + var(--border-offset)) rgba(var(--color-button-text), var(--border-opacity)),
    0 0 0 var(--buttons-border-width) rgba(var(--color-button), var(--alpha-button-background));
  transition: box-shadow var(--duration-short) ease;
}

.button:not([disabled]):hover::after,
.shopify-challenge__button:hover::after,
.customer button:hover::after,
.shopify-payment-button__button--unbranded:hover::after {
  --border-offset: 1.3px;
  box-shadow: 0 0 0 calc(var(--buttons-border-width) + var(--border-offset)) rgba(var(--color-button-text), var(--border-opacity)),
    0 0 0 calc(var(--buttons-border-width) + 1px) rgba(var(--color-button), var(--alpha-button-background));
}

.button--secondary:after {
  --border-opacity: var(--buttons-border-opacity);
}

.button:focus-visible,
.button:focus,
.button.focused,
.shopify-payment-button__button--unbranded:focus-visible,
.shopify-payment-button [role="button"]:focus-visible,
.shopify-payment-button__button--unbranded:focus,
.shopify-payment-button [role="button"]:focus {
  outline: 0;
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0 0.5rem rgba(var(--color-foreground), 0.5),
    0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3);
}

.button:focus:not(:focus-visible):not(.focused),
.shopify-payment-button__button--unbranded:focus:not(:focus-visible):not(.focused),
.shopify-payment-button [role="button"]:focus:not(:focus-visible):not(.focused) {
  box-shadow: inherit;
}

.button::selection,
.shopify-challenge__button::selection,
.customer button::selection {
  background-color: rgba(var(--color-button-text), 0.3);
}

.button,
.button-label,
.shopify-challenge__button,
.customer button {
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  line-height: calc(1 + 0.2 / var(--font-body-scale));
}

.button--tertiary {
  font-size: 1.2rem;
  padding: 1rem 1.5rem;
  min-width: calc(9rem + var(--buttons-border-width) * 2);
  min-height: calc(3.5rem + var(--buttons-border-width) * 2);
}

.button--small {
  padding: 1.2rem 2.6rem;
}

/* Button - other */

.button:disabled,
.button[aria-disabled='true'],
.button.disabled,
.customer button:disabled,
.customer button[aria-disabled='true'],
.customer button.disabled,
.quantity__button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button--full-width {
  display: flex;
  width: 100%;
}

.button.loading {
  color: transparent;
  position: relative;
}

@media screen and (forced-colors: active) {
  .button.loading {
    color: rgb(var(--color-foreground));
  }
}

.button.loading > .loading-overlay__spinner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
}

.button.loading > .loading-overlay__spinner .spinner {
  width: fit-content;
}

.button.loading > .loading-overlay__spinner .path {
  stroke: rgb(var(--color-button-text));
}

/* Button - social share */

.share-button {
  display: block;
  position: relative;
}

.share-button details {
  width: fit-content;
}

.share-button__button {
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: rgb(var(--color-link));
  margin-left: 0;
  padding-left: 0;
  min-height: 4.4rem;
}

details[open] > .share-button__fallback {
  animation: animateMenuOpen var(--duration-default) ease;
}

.share-button__button:hover {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

.share-button__button,
.share-button__fallback button {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.share-button__button .icon-share {
  height: 1.2rem;
  margin-right: 1rem;
  min-width: 1.3rem;
}

.share-button__fallback {
  display: flex;
  align-items: center;
  position: absolute;
  top: 3rem;
  left: 0.1rem;
  z-index: 3;
  width: 100%;
  min-width: max-content;
  border-radius: var(--inputs-radius);
  border: 0;
}

.share-button__fallback:after {
  pointer-events: none;
  content: '';
  position: absolute;
  top: var(--inputs-border-width);
  right: var(--inputs-border-width);
  bottom: var(--inputs-border-width);
  left: var(--inputs-border-width);
  border: 0.1rem solid transparent;
  border-radius: var(--inputs-radius);
  box-shadow: 0 0 0 var(--inputs-border-width) rgba(var(--color-foreground), var(--inputs-border-opacity));
  transition: box-shadow var(--duration-short) ease;
  z-index: 1;
}

.share-button__fallback:before {
  background: rgb(var(--color-background));
  pointer-events: none;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--inputs-radius-outset);
  box-shadow: var(--inputs-shadow-horizontal-offset) var(--inputs-shadow-vertical-offset) var(--inputs-shadow-blur-radius) rgba(var(--color-base-text), var(--inputs-shadow-opacity));
  z-index: -1;
}

.share-button__fallback button {
  width: 4.4rem;
  height: 4.4rem;
  padding: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: var(--inputs-border-width);
}

.share-button__fallback button:hover {
  color: rgba(var(--color-foreground), 0.75);
}

.share-button__fallback button:hover svg {
  transform: scale(1.07);
}

.share-button__close:not(.hidden) + .share-button__copy {
  display: none;
}

.share-button__close,
.share-button__copy {
  background-color: transparent;
  color: rgb(var(--color-foreground));
}

.share-button__copy:focus-visible,
.share-button__close:focus-visible {
  background-color: rgb(var(--color-background));
  z-index: 2;
}

.share-button__copy:focus,
.share-button__close:focus {
  background-color: rgb(var(--color-background));
  z-index: 2;
}

.field:not(:focus-visible):not(.focused) + .share-button__copy:not(:focus-visible):not(.focused),
.field:not(:focus-visible):not(.focused) + .share-button__close:not(:focus-visible):not(.focused) {
  background-color: inherit;
}

.share-button__fallback .field:after,
.share-button__fallback .field:before {
  content: none;
}

.share-button__fallback .field {
  border-radius: 0;
  min-width: auto;
  min-height: auto;
  transition: none;
}

.share-button__fallback .field__input:focus,
.share-button__fallback .field__input:-webkit-autofill {
  outline: 0.2rem solid rgba(var(--color-foreground),.5);
  outline-offset: 0.1rem;
  box-shadow: 0 0 0 0.1rem rgb(var(--color-background)),0 0 0.5rem 0.4rem rgba(var(--color-foreground),.3);
}

.share-button__fallback .field__input {
  box-shadow: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  filter: none;
  min-width: auto;
  min-height: auto;
}

.share-button__fallback .field__input:hover {
  box-shadow: none;
}

.share-button__fallback .icon {
  width: 1.5rem;
  height: 1.5rem;
}

.share-button__message:not(:empty) {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 0;
  padding: 0.8rem 0 0.8rem 1.5rem;
  margin: var(--inputs-border-width);
}

.share-button__message:not(:empty):not(.hidden) ~ * {
  display: none;
}

/* component-form */
.field__input,
.select__select,
.customer .field input,
.customer select {
  -webkit-appearance: none;
  appearance: none;
  background-color: rgb(var(--color-background));
  color: rgb(var(--color-foreground));
  font-size: 1.6rem;
  width: 100%;
  box-sizing: border-box;
  transition: box-shadow var(--duration-short) ease;
  border-radius: var(--inputs-radius);
  height: 4.5rem;
  min-height: calc(var(--inputs-border-width) * 2);
  min-width: calc(7rem + (var(--inputs-border-width) * 2));
  position: relative;
  border: 0;
}

.field:before,
.select:before,
.customer .field:before,
.customer select:before,
.localization-form__select:before {
  pointer-events: none;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--inputs-radius-outset);
  box-shadow: var(--inputs-shadow-horizontal-offset) var(--inputs-shadow-vertical-offset) var(--inputs-shadow-blur-radius) rgba(var(--color-base-text), var(--inputs-shadow-opacity));
  z-index: -1;
}

.field:after,
.select:after,
.customer .field:after,
.customer select:after,
.localization-form__select:after {
  pointer-events: none;
  content: '';
  position: absolute;
  top: var(--inputs-border-width);
  right: var(--inputs-border-width);
  bottom: var(--inputs-border-width);
  left: var(--inputs-border-width);
  border: 0.1rem solid transparent;
  border-radius: var(--inputs-radius);
  box-shadow: 0 0 0 var(--inputs-border-width) rgba(var(--color-foreground), var(--inputs-border-opacity));
  transition: box-shadow var(--duration-short) ease;
  z-index: 1;
}

.select__select {
  font-family: var(--font-body-family);
  font-style: var(--font-body-style);
  font-weight: var(--font-body-weight);
  font-size: 1.2rem;
  color: rgba(var(--color-foreground), 0.75);
}

.field:hover.field:after,
.select:hover.select:after,
.select__select:hover.select__select:after,
.customer .field:hover.field:after,
.customer select:hover.select:after,
.localization-form__select:hover.localization-form__select:after {
  box-shadow: 0 0 0 calc(0.1rem + var(--inputs-border-width)) rgba(var(--color-foreground),var(--inputs-border-opacity));
  outline: 0;
  border-radius: var(--inputs-radius);
}

.field__input:focus-visible,
.select__select:focus-visible,
.customer .field input:focus-visible,
.customer select:focus-visible,
.localization-form__select:focus-visible.localization-form__select:after {
  box-shadow: 0 0 0 calc(0.1rem + var(--inputs-border-width)) rgba(var(--color-foreground));
  outline: 0;
  border-radius: var(--inputs-radius);
}

.field__input:focus,
.select__select:focus,
.customer .field input:focus,
.customer select:focus,
.localization-form__select:focus.localization-form__select:after {
  box-shadow: 0 0 0 calc(0.1rem + var(--inputs-border-width)) rgba(var(--color-foreground));
  outline: 0;
  border-radius: var(--inputs-radius);
}

.localization-form__select:focus {
  outline: 0;
  box-shadow: none;
}

.text-area,
.select {
  display: flex;
  position: relative;
  width: 100%;
}

/* Select */

.select .icon-caret,
.customer select + svg {
  height: 0.6rem;
  pointer-events: none;
  position: absolute;
  top: calc(50% - 0.2rem);
  right: calc(var(--inputs-border-width) + 1.5rem);
}

.select__select,
.customer select {
  cursor: pointer;
  line-height: calc(1 + 0.6 / var(--font-body-scale));
  padding: 0 calc(var(--inputs-border-width) + 3rem) 0 2rem;
  margin: var(--inputs-border-width);
  min-height: calc(var(--inputs-border-width) * 2);
}

/* Field */

.field {
  position: relative;
  width: 100%;
  display: flex;
  transition: box-shadow var(--duration-short) ease;
}

.customer .field {
  display: flex;
}

.field--with-error {
  flex-wrap: wrap;
}

.field__input,
.customer .field input {
  flex-grow: 1;
  text-align: left;
  padding: 1.5rem;
  margin: var(--inputs-border-width);
  transition: box-shadow var(--duration-short) ease;
}

.field__label,
.customer .field label {
  font-size: 1.6rem;
  left: calc(var(--inputs-border-width) + 2rem);
  top: calc(1rem + var(--inputs-border-width));
  margin-bottom: 0;
  pointer-events: none;
  position: absolute;
  transition: top var(--duration-short) ease,
    font-size var(--duration-short) ease;
  color: rgba(var(--color-foreground), 0.75);
  letter-spacing: 0.1rem;
  line-height: 1.5;
}

.field__input:focus ~ .field__label,
.field__input:not(:placeholder-shown) ~ .field__label,
.field__input:-webkit-autofill ~ .field__label,
.customer .field input:focus ~ label,
.customer .field input:not(:placeholder-shown) ~ label,
.customer .field input:-webkit-autofill ~ label {
  font-size: 1rem;
  top: calc(var(--inputs-border-width) + 0.5rem);
  left: calc(var(--inputs-border-width) + 2rem);
  letter-spacing: 0.04rem;
}

.field__input:focus,
.field__input:not(:placeholder-shown),
.field__input:-webkit-autofill,
.customer .field input:focus,
.customer .field input:not(:placeholder-shown),
.customer .field input:-webkit-autofill {
  padding: 2.2rem 1.5rem 0.8rem 2rem;
  margin: var(--inputs-border-width);
}

.field__input::-webkit-search-cancel-button,
.customer .field input::-webkit-search-cancel-button {
  display: none;
}

.field__input::placeholder,
.customer .field input::placeholder {
  opacity: 0;
}

.field__button {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: currentColor;
  cursor: pointer;
  display: flex;
  height: 4.4rem;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 4.4rem;
}

.field__button > svg {
  height: 2.5rem;
  width: 2.5rem;
}

.field__input:-webkit-autofill ~ .field__button,
.field__input:-webkit-autofill ~ .field__label,
.customer .field input:-webkit-autofill ~ label {
  color: rgb(0, 0, 0);
}

/* Text area */

.text-area {
  font-family: var(--font-body-family);
  font-style: var(--font-body-style);
  font-weight: var(--font-body-weight);
  min-height: 10rem;
  resize: none;
}

input[type='checkbox'] {
  display: inline-block;
  width: auto;
  margin-right: 0.5rem;
}

/* Form global */

.form__label {
  display: block;
  margin-bottom: 0.6rem;
}

.form__message {
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  line-height: 1;
  margin-top: 1rem;
}

.form__message--large {
  font-size: 1.6rem;
}

.customer .field .form__message {
  font-size: 1.4rem;
  text-align: left;
}

.form__message .icon,
.customer .form__message svg {
  flex-shrink: 0;
  height: 1.3rem;
  margin-right: 0.5rem;
  width: 1.3rem;
}

.form__message--large .icon,
.customer .form__message svg {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
}

.customer .field .form__message svg {
  align-self: start;
}

.form-status {
  margin: 0;
  font-size: 1.6rem;
}

.form-status-list {
  padding: 0;
  margin: 2rem 0 4rem;
}

.form-status-list li {
  list-style-position: inside;
}

.form-status-list .link::first-letter {
  text-transform: capitalize;
}

/* component-quantity */
.quantity {
  color: rgba(var(--color-foreground));
  position: relative;
  width: calc(14rem / var(--font-body-scale) + var(--inputs-border-width) * 2);
  display: flex;
  border-radius: var(--inputs-radius);
  min-height: calc((var(--inputs-border-width) * 2) + 4.5rem);
}

.quantity:after {
  pointer-events: none;
  content: '';
  position: absolute;
  top: var(--inputs-border-width);
  right: var(--inputs-border-width);
  bottom: var(--inputs-border-width);
  left: var(--inputs-border-width);
  border: 0.1rem solid transparent;
  border-radius: var(--inputs-radius);
  box-shadow: 0 0 0 var(--inputs-border-width) rgba(var(--color-foreground), var(--inputs-border-opacity));
  transition: box-shadow var(--duration-short) ease;
  z-index: 1;
}

.quantity:before {
  background: rgb(var(--color-background));
  pointer-events: none;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--inputs-radius-outset);
  box-shadow: var(--inputs-shadow-horizontal-offset) var(--inputs-shadow-vertical-offset) var(--inputs-shadow-blur-radius) rgba(var(--color-base-text), var(--inputs-shadow-opacity));
  z-index: -1;
}

.quantity__input {
  color: currentColor;
  font-size: 1.4rem;
  font-weight: 500;
  opacity: 0.85;
  text-align: center;
  background-color: transparent;
  border: 0;
  padding: 0 0.5rem;
  width: 100%;
  flex-grow: 1;
  -webkit-appearance: none;
  appearance: none;
}

.quantity__button {
  width: calc(4.5rem / var(--font-body-scale));
  flex-shrink: 0;
  font-size: 1.8rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(var(--color-foreground));
  padding: 0;
}

.quantity__button:first-child {
  margin-left: calc(var(--inputs-border-width));
}

.quantity__button:last-child {
  margin-right: calc(var(--inputs-border-width));
}

.quantity__button svg {
  width: 1rem;
  pointer-events: none;
}

.quantity__button:focus-visible,
.quantity__input:focus-visible {
  background-color: rgb(var(--color-background));
  z-index: 2;
}

.quantity__button:focus,
.quantity__input:focus {
  background-color: rgb(var(--color-background));
  z-index: 2;
}

.quantity__button:not(:focus-visible):not(.focused),
.quantity__input:not(:focus-visible):not(.focused) {
  box-shadow: inherit;
  background-color: inherit;
}

.quantity__input:-webkit-autofill,
.quantity__input:-webkit-autofill:hover,
.quantity__input:-webkit-autofill:active {
  box-shadow: 0 0 0 10rem rgb(var(--color-background)) inset !important;
  -webkit-box-shadow: 0 0 0 10rem rgb(var(--color-background)) inset !important;
}

.quantity__input::-webkit-outer-spin-button,
.quantity__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity__input[type='number'] {
  -moz-appearance: textfield;
}

.quantity__rules {
  margin-top: 1.2rem;
  position: relative;
  font-size: 1.2rem;
}

.quantity__rules .caption {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
}

.quantity__rules .divider + .divider::before {
  content: '\2022';
  margin: 0 0.5rem;
}

.quantity__rules-cart {
  position: relative;
}

product-info .loading-overlay:not(.hidden) ~ *,
.quantity__rules-cart .loading-overlay:not(.hidden) ~ * {
  visibility: hidden;
}

/* component-modal */
.modal__toggle {
  list-style-type: none;
}

.no-js details[open] .modal__toggle {
  position: absolute;
  z-index: 5;
}

.modal__toggle-close {
  display: none;
}

.no-js details[open] svg.modal__toggle-close {
  display: flex;
  z-index: 1;
  height: 1.7rem;
  width: 1.7rem;
}

.modal__toggle-open {
  display: flex;
}

.no-js details[open] .modal__toggle-open {
  display: none;
}

.no-js .modal__close-button.link {
  display: none;
}

.modal__close-button.link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem;
  height: 4.4rem;
  width: 4.4rem;
  background-color: transparent;
}

.modal__close-button .icon {
  width: 1.7rem;
  height: 1.7rem;
}

.modal__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(var(--color-background));
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-modal {
  cursor: zoom-out;
}

.media-modal .deferred-media {
  cursor: initial;
}

/* component-cart-count-bubble */
.cart-count-bubble:empty {
  display: none;
}

.cart-count-bubble {
  position: absolute;
  background-color: rgb(var(--color-button));
  color: rgb(var(--color-button-text));
  height: 1.7rem;
  width: 1.7rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  bottom: 0.8rem;
  left: 2.2rem;
  line-height: calc(1 + 0.1 / var(--font-body-scale));
}

/* section-announcement-bar */
.announcement-bar {
  border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.08);
  color: rgb(var(--color-foreground));
}

.announcement-bar__link {
  display: block;
  width: 100%;
  padding: 1rem 0;
  text-decoration: none;
}

.announcement-bar__link:hover {
  color: rgb(var(--color-foreground));
  background-color: rgba(var(--color-card-hover), 0.06);
}

.announcement-bar__link .icon-arrow {
  display: inline-block;
  pointer-events: none;
  margin-left: 0.8rem;
  vertical-align: middle;
  margin-bottom: 0.2rem;
}

.announcement-bar__link .announcement-bar__message {
  padding: 0;
}

.announcement-bar__message {
  padding: 1rem 0;
  margin: 0;
  letter-spacing: 0.1rem;
}

/* section-header */
.section-header.shopify-section-group-header-group {
  z-index: 3;
}

.shopify-section-header-sticky {
  position: sticky;
  top: 0;
}

.shopify-section-header-hidden {
  top: calc(-1 * var(--header-height));
}

.shopify-section-header-hidden.menu-open {
  top: 0;
}

.section-header.animate {
  transition: top 0.15s ease-out;
}

.shopify-section-group-header-group {
  z-index: 4;
}

.section-header ~ .shopify-section-group-header-group {
  z-index: initial;
}

/* Main Header Layout */
.header-wrapper {
  display: block;
  position: relative;
  background-color: rgb(var(--color-background));
}

.header-wrapper--border-bottom {
  border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.08);
}

.header {
  display: grid;
  grid-template-areas: 'left-icon heading icons';
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
}

@media screen and (min-width: 990px) {
  .header--top-left,
  .header--middle-left:not(.header--has-menu) {
    grid-template-areas:
      'heading icons'
      'navigation navigation';
    grid-template-columns: 1fr auto;
  }

  .header--middle-left {
    grid-template-areas: 'heading navigation icons';
    grid-template-columns: auto auto 1fr;
    column-gap: 2rem;
  }

  .header--middle-center {
    grid-template-areas: 'navigation heading icons';
    grid-template-columns: 1fr auto 1fr;
    column-gap: 2rem;
  }

  .header--middle-center a.header__heading-link {
    text-align: center;
  }

  .header--top-center {
    grid-template-areas:
      'left-icon heading icons'
      'navigation navigation navigation';
  }

  .header:not(.header--middle-left, .header--middle-center) .header__inline-menu {
    margin-top: 1.05rem;
  }
}

.header *[tabindex='-1']:focus {
  outline: none;
}

.header__heading {
  margin: 0;
  line-height: 0;
}

.header > .header__heading-link {
  line-height: 0;
}

.header__heading,
.header__heading-link {
  grid-area: heading;
  justify-self: center;
}

.header__heading-link {
  display: inline-block;
  padding: 0.75rem;
  text-decoration: none;
  word-break: break-word;
}

.header__heading-link:hover .h2 {
  color: rgb(var(--color-foreground));
}

.header__heading-link .h2 {
  line-height: 1;
  color: rgba(var(--color-foreground), 0.75);
}

.header__heading-logo {
  height: auto;
  width: 100%;
  transition: width 0.3s cubic-bezier(0.52, 0, 0.61, 0.99);
}

@media screen and (max-width: 989px) {
  .header__heading,
  .header__heading-link {
    text-align: center;
  }

  .header--mobile-left .header__heading,
  .header--mobile-left .header__heading-link {
    text-align: left;
    justify-self: start;
  }

  .header--mobile-left {
    grid-template-columns: auto 2fr 1fr;
  }
}

@media screen and (min-width: 990px) {
  .header__heading-link {
    margin-left: -0.75rem;
  }

  .header__heading,
  .header__heading-link {
    justify-self: start;
  }

  .header--top-center .header__heading-link,
  .header--top-center .header__heading {
    justify-self: center;
    text-align: center;
  }
}

/* Header icons */
.header__icons {
  display: flex;
  grid-area: icons;
  justify-self: end;
}

.header__icon:not(.header__icon--summary),
.header__icon span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__icon {
  color: rgb(var(--color-foreground));
}

.header__icon span {
  height: 100%;
}

.header__icon::after {
  content: none;
}

.header__icon:hover .icon,
.modal__close-button:hover .icon {
  transform: scale(1.07);
}

.header__icon .icon {
  height: 2rem;
  width: 2rem;
  fill: none;
  vertical-align: middle;
}

.header__icon,
.header__icon--cart .icon {
  height: 4.4rem;
  width: 4.4rem;
  padding: 0;
}

.header__icon--cart {
  position: relative;
  margin-right: -1.2rem;
}

@media screen and (max-width: 989px) {
  menu-drawer ~ .header__icons .header__icon--account {
    display: none;
  }
}

.header__icon--menu[aria-expanded="true"]::before {
  content: "";
  top: 100%;
  left: 0;
  height: calc(var(--viewport-height, 100vh) - (var(--header-bottom-position, 100%)));
  width: 100%;
  display: block;
  position: absolute;
  background: rgba(var(--color-foreground), 0.5);
}

/* Search */
menu-drawer + .header__search {
  display: none;
}

.header > .header__search {
  grid-area: left-icon;
  justify-self: start;
}

.header:not(.header--has-menu) * > .header__search {
  display: none;
}

.header__search {
  display: inline-flex;
  line-height: 0;
}

.header--top-center > .header__search {
  display: none;
}

.header--top-center * > .header__search {
  display: inline-flex;
}

@media screen and (min-width: 990px) {
  .header:not(.header--top-center) * > .header__search,
  .header--top-center > .header__search {
    display: inline-flex;
  }

  .header:not(.header--top-center) > .header__search,
  .header--top-center * > .header__search {
    display: none;
  }
}

.no-js .predictive-search {
  display: none;
}

details[open] > .search-modal {
  opacity: 1;
  animation: animateMenuOpen var(--duration-default) ease;
}

details[open] .modal-overlay {
  display: block;
}

details[open] .modal-overlay::after {
  position: absolute;
  content: '';
  background-color: rgb(var(--color-foreground), 0.5);
  top: 100%;
  left: 0;
  right: 0;
  height: 100vh;
}

.no-js details[open] > .header__icon--search {
  top: 1rem;
  right: 0.5rem;
}

.search-modal {
  opacity: 0;
  border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.08);
  min-height: calc(100% + var(--inputs-margin-offset) + (2 * var(--inputs-border-width)));
  height: 100%;
}

.search-modal__content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 5rem 0 1rem;
  line-height: calc(1 + 0.8 / var(--font-body-scale));
  position: relative;
}

.search-modal__content-bottom {
  bottom: calc((var(--inputs-margin-offset) / 2) );
}

.search-modal__content-top {
  top: calc((var(--inputs-margin-offset) / 2) );
}

.search-modal__form {
  width: 100%;
}

.search-modal__close-button {
  position: absolute;
  right: 0.3rem;
}

@media screen and (min-width: 750px) {
  .search-modal__close-button {
    right: 1rem;
  }

  .search-modal__content {
    padding: 0 6rem;
  }
}

@media screen and (min-width: 990px) {
  .search-modal__form {
    max-width: 74.2rem;
  }

  .search-modal__close-button {
    position: initial;
    margin-left: 0.5rem;
  }
}

/* Header menu drawer */
.header__icon--menu .icon {
  display: block;
  position: absolute;
  opacity: 1;
  transform: scale(1);
  transition: transform 150ms ease, opacity 150ms ease;
}

details:not([open]) > .header__icon--menu .icon-close,
details[open] > .header__icon--menu .icon-hamburger {
  visibility: hidden;
  opacity: 0;
  transform: scale(0.8);
}

.js details[open]:not(.menu-opening) > .header__icon--menu .icon-close {
  visibility: hidden;
}

.js details[open]:not(.menu-opening) > .header__icon--menu .icon-hamburger {
  visibility: visible;
  opacity: 1;
  transform: scale(1.07);
}

.js details > .header__submenu {
  opacity: 0;
  transform: translateY(-1.5rem);
}

details[open] > .header__submenu {
  animation: animateMenuOpen var(--duration-default) ease;
  animation-fill-mode: forwards;
  z-index: 1;
}

@media (prefers-reduced-motion) {
  details[open] > .header__submenu {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Header menu */
.header__inline-menu {
  margin-left: -1.2rem;
  grid-area: navigation;
  display: none;
}

.header--top-center .header__inline-menu,
.header--top-center .header__heading-link {
  margin-left: 0;
}

@media screen and (min-width: 990px) {
  .header__inline-menu {
    display: block;
  }

  .header--top-center .header__inline-menu {
    justify-self: center;
  }

  .header--top-center .header__inline-menu > .list-menu--inline {
    justify-content: center;
  }

  .header--middle-left .header__inline-menu {
    margin-left: 0;
  }
}

.header__menu {
  padding: 0 1rem;
}

.header__menu-item {
  padding: 1.2rem;
  text-decoration: none;
  color: rgba(var(--color-foreground), 0.75);
}

.header__menu-item:hover {
  color: rgb(var(--color-foreground));
}

.header__menu-item span {
  transition: text-decoration var(--duration-short) ease;
}

.header__menu-item:hover span {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

details[open] > .header__menu-item {
  text-decoration: underline;
}

details[open]:hover > .header__menu-item {
  text-decoration-thickness: 0.2rem;
}

details[open] > .header__menu-item .icon-caret {
  transform: rotate(180deg);
}

.header__active-menu-item {
  transition: text-decoration-thickness var(--duration-short) ease;
  color: rgb(var(--color-foreground));
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

.header__menu-item:hover .header__active-menu-item {
  text-decoration-thickness: 0.2rem;
}

.header__submenu {
  transition: opacity var(--duration-default) ease,
    transform var(--duration-default) ease;
}

.global-settings-popup,
.header__submenu.global-settings-popup {
  border-radius: var(--popup-corner-radius);
  border-color: rgba(var(--color-foreground), var(--popup-border-opacity));
  border-style: solid;
  border-width: var(--popup-border-width);
  box-shadow: var(--popup-shadow-horizontal-offset) var(--popup-shadow-vertical-offset) var(--popup-shadow-blur-radius) rgba(var(--color-shadow), var(--popup-shadow-opacity));
  z-index: -1;
}

.header__submenu.list-menu {
  padding: 2.4rem 0;
}

.header__submenu .header__submenu {
  background-color: rgba(var(--color-foreground), 0.03);
  padding: 0.5rem 0;
  margin: 0.5rem 0;
}

.header__submenu .header__menu-item:after {
  right: 2rem;
}

.header__submenu .header__menu-item {
  justify-content: space-between;
  padding: 0.8rem 2.4rem;
}

.header__submenu .header__submenu .header__menu-item {
  padding-left: 3.4rem;
}

.header__menu-item .icon-caret {
  right: 0.8rem;
}

.header__submenu .icon-caret {
  flex-shrink: 0;
  margin-left: 1rem;
  position: static;
}

header-menu > details,
details-disclosure > details {
  position: relative;
}

@keyframes animateMenuOpen {
  0% {
    opacity: 0;
    transform: translateY(-1.5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.overflow-hidden-mobile,
.overflow-hidden-tablet {
  overflow: hidden;
}

@media screen and (min-width: 750px) {
  .overflow-hidden-mobile {
    overflow: auto;
  }
}

@media screen and (min-width: 990px) {
  .overflow-hidden-tablet {
    overflow: auto;
  }
}

.badge {
  border: 1px solid transparent;
  border-radius: var(--badge-corner-radius);
  display: inline-block;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  line-height: 1;
  padding: 0.5rem 1.3rem 0.6rem 1.3rem;
  text-align: center;
  background-color: rgb(var(--color-badge-background));
  border-color: rgba(var(--color-badge-border), var(--alpha-badge-border));
  color: rgb(var(--color-foreground));
  word-break: break-word;
}

.gradient {
  background: rgb(var(--color-background));
  background: var(--gradient-background);
  background-attachment: fixed;
}

@media screen and (forced-colors: active) {
  .icon {
    color: CanvasText;
    fill: CanvasText !important;
  }

  .icon-close-small path {
    stroke: CanvasText;
  }
}

.ratio {
  display: flex;
  position: relative;
  align-items: stretch;
}

.ratio::before {
  content: '';
  width: 0;
  height: 0;
  padding-bottom: var(--ratio-percent);
}

.content-container {
  border-radius: var(--text-boxes-radius);
  border: var(--text-boxes-border-width) solid rgba(var(--color-foreground), var(--text-boxes-border-opacity));
  position: relative;
}

.content-container:after {
  content: '';
  position: absolute;
  top: calc(var(--text-boxes-border-width) * -1);
  right: calc(var(--text-boxes-border-width) * -1);
  bottom: calc(var(--text-boxes-border-width) * -1);
  left: calc(var(--text-boxes-border-width) * -1);
  border-radius: var(--text-boxes-radius);
  box-shadow: var(--text-boxes-shadow-horizontal-offset)
    var(--text-boxes-shadow-vertical-offset)
    var(--text-boxes-shadow-blur-radius)
    rgba(var(--color-shadow), var(--text-boxes-shadow-opacity));
  z-index: -1;
}

.content-container--full-width:after {
  left: 0;
  right: 0;
  border-radius: 0;
}

@media screen and (max-width: 749px) {
  .content-container--full-width-mobile {
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
  .content-container--full-width-mobile:after {
    display: none;
  }
}

.global-media-settings {
  position: relative;
  border: var(--media-border-width) solid rgba(var(--color-foreground), var(--media-border-opacity));
  border-radius: var(--media-radius);
  overflow: visible !important;
  background-color: rgb(var(--color-background));
}

.global-media-settings:after {
  content: '';
  position: absolute;
  top: calc(var(--media-border-width) * -1);
  right: calc(var(--media-border-width) * -1);
  bottom: calc(var(--media-border-width) * -1);
  left: calc(var(--media-border-width) * -1);
  border-radius: var(--media-radius);
  box-shadow: var(--media-shadow-horizontal-offset) var(--media-shadow-vertical-offset) var(--media-shadow-blur-radius) rgba(var(--color-shadow), var(--media-shadow-opacity));
  z-index: -1;
  pointer-events: none;
}

.global-media-settings--no-shadow {
  overflow: hidden !important;
}

.global-media-settings--no-shadow:after {
  content: none;
}

.global-media-settings img,
.global-media-settings iframe,
.global-media-settings model-viewer,
.global-media-settings video {
  border-radius: calc(var(--media-radius) - var(--media-border-width));
}

.content-container--full-width,
.global-media-settings--full-width,
.global-media-settings--full-width img {
  border-radius: 0;
  border-left: none;
  border-right: none;
}

/* check for flexbox gap in older Safari versions */
@supports not (inset: 10px) {
  .grid {
    margin-left: calc(-1 * var(--grid-mobile-horizontal-spacing));
  }

  .grid__item {
    padding-left: var(--grid-mobile-horizontal-spacing);
    padding-bottom: var(--grid-mobile-vertical-spacing);
  }

  @media screen and (min-width: 750px) {
    .grid {
      margin-left: calc(-1 * var(--grid-desktop-horizontal-spacing));
    }

    .grid__item {
      padding-left: var(--grid-desktop-horizontal-spacing);
      padding-bottom: var(--grid-desktop-vertical-spacing);
    }
  }

  .grid--gapless .grid__item {
    padding-left: 0;
    padding-bottom: 0;
  }

  @media screen and (min-width: 749px) {
    .grid--peek .grid__item {
      padding-left: var(--grid-mobile-horizontal-spacing);
    }
  }

  .product-grid .grid__item {
    padding-bottom: var(--grid-mobile-vertical-spacing);
  }

  @media screen and (min-width: 750px) {
    .product-grid .grid__item {
      padding-bottom: var(--grid-desktop-vertical-spacing);
    }
  }
}

.font-body-bold {
  font-weight: var(--font-body-weight-bold);
}

/* outline and border styling for Windows High Contrast Mode */
@media (forced-colors: active) {
  .button,
  .shopify-challenge__button,
  .customer button {
    border: transparent solid 1px;
  }

  .button:focus-visible,
  .button:focus,
  .button.focused,
  .shopify-payment-button__button--unbranded:focus-visible,
  .shopify-payment-button [role="button"]:focus-visible,
  .shopify-payment-button__button--unbranded:focus,
  .shopify-payment-button [role="button"]:focus {
    outline: solid transparent 1px;
  }

  .field__input:focus,
  .select__select:focus,
  .customer .field input:focus,
  .customer select:focus,
  .localization-form__select:focus.localization-form__select:after {
    outline: transparent solid 1px;
  }

  .localization-form__select:focus {
    outline: transparent solid 1px;
  }
}
